/* starting ENTER animation */
.messageout-enter {
    transform: translateY(0%) scale(0.01);
  }
  
  /* ending ENTER animation */
  .messageout-enter-active {
    transform: translateY(0%) scale(1);
    transition: all 500ms ease-in-out;
  }
  
  /* starting EXIT animation */
  .stepstepscardAnimationscard-exit {
    transform: translateY(0%) scale(1);
  }
  
  /* ending EXIT animation */
  .stepscardAnimation-exit-active {
    transform: translateY(0%) scale(0);
    transition: all 500ms ease-in-out;
  }
.ticket-New {
    border-left: solid 4px $blue;
}
  
.ticket-Open {
    border-left: solid 4px $green;
}

.ticket-Resolved {
    border-left: solid 4px $yellow;
}

.ticket-Scheduled {
    border-left: solid 4px $nav-gray;
}

.ticket-WaitOnCust {
    border-left: solid 4px $red;
}

.ticket-New-background {
    color: $blue;
}
  
.ticket-Open-background {
    color: $green;
}

.ticket-Resolved-background {
    color: $yellow;
}

.ticket-Scheduled-background {
    color: $nav-gray;
}

.ticket-WaitOnCust-background {
    color: $red;
}

.connectria-tickets-detail-blade h4 {
    color: $black;
}

.connectria-tickets-detail-blade blockquote {
    border-right: unset;
    border-top: unset;
    border-bottom: unset;
    padding: 0 0 0 10px;
}

.connectria-tickets-detail-blade blockquote.right {
    border-left: unset;
    border-right: 0.2em solid lighten($black, 40%);
    padding: 0 10px 0 10px;
}

.connectria-tickets-detail-blade blockquote:before,
.connectria-tickets-detail-blade blockquote:after {
    display: none;
}

.connectria-tickets-detail-blade .table tbody tr td {
    padding-top:0;
    padding-bottom:0;
    color: $black !important;
}

#taDescription {
    background-color: $gray-200;
    color: $black;
    padding:10px;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    white-space: pre-wrap;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.connectria-tickets-detail-blade a.nav-link {
    color: $black !important;
    cursor: pointer;
}

.connectria-tickets-detail-blade  a.nav-link.active {
    font-weight: bold;
}

.connectria-tickets-detail-blade .nav-tabs .nav-link {
    border-bottom: solid 1px $dark-gray;
}

.connectria-tickets-detail-blade .nav-tabs .nav-link.active {
    border-bottom-color: $nav-gray;
}

#connectria-support-recent-updates-body {
    height:307px;
    overflow:scroll;
}

#connectria-ticket-db-tabs,
#connectria-ticket-priority-card {
    margin-top:0px;
}

.attachment-block {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
    margin: 10px 0 10px 0;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.attachment-block > i {
    font-size:57px;
    margin-right: 15px;
}

.connectria-tickets-inbox-btn {
    font-size:21px;
    color: $blue;
    margin-left:15px;
    cursor: pointer;
    margin-top:3px;
}

//override styles when new ticket form is inside the blade
.blade-panel-content .connectria-new-ticket-form-contain {
    height: unset !important;
}

.blade-panel-content .connectria-new-ticket-form-contain .col-lg-6 {
    max-width: 100%;
    flex: 0 0 100%;
}

.blade-panel-content .connectria-new-ticket-form-contain label {
    margin-top: 0.5rem;
    margin-bottom: 0;
}

@media screen and (max-width: 991px){
    #connectria-support-recent-updates-body {
        height:unset;
        overflow:unset;
    }
    #connectria-ticket-db-tabs,
    #connectria-ticket-priority-card {
        margin-top:10px;
    }
}

@include media-breakpoint-down(md) {
    #connectria-ticket-db-body-title {
        display:none;
    }
}

textarea, div .resizable{
    resize: vertical;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: $danger-states; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: $danger-states;
    box-shadow: 0 0 0 0 rgba(255, 141, 114, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.bubble {
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    max-width: 320px;
    padding: 16px;
    text-align: left;
}

.bubble-left {
    background: $blue1-20;
    color: $gray6;
    border-radius: 15px 15px 15px 0px;
}
.bubble-right {
    background: $blue1;
    color: #fff;
    border-radius: 15px 15px 0px 15px;
}

.bubble-left:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 7px solid $blue1-20;
    border-right: 5px solid transparent;
    border-top: 5px solid $blue1-20;
    border-bottom: 9px solid transparent;
    border-bottom-left-radius: 3px;
    left: 0px;
    bottom: -10px;
}

.bubble-right:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 7px solid $blue1;
    border-top: 5px solid $blue1;
    border-bottom: 9px solid transparent;
    border-bottom-right-radius: 3px;
    right: 0px;
    bottom: -10px;
} 
