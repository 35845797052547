.table{
  > tbody > tr > td {
    color: rgba(255, 255, 255, 0.7) !important;

    .photo {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }

  > tbody > tr > td.dropdown-cell {
    overflow: visible;
  }

  > tbody > tr > td.editable-cell:hover {
    //border-color: yellow;
    background-color: rgba(255, 255, 51, 0.3);

  }

  > tbody > tr.table-success > td{
    background-color: darken($success,10%);
  }

  > tbody > tr.table-info > td{
    background-color: $info;
  }

  > tbody > tr.table-primary > td{
    background-color: $primary;
  }

  > tbody > tr.table-warning > td{
    background-color: $warning;
  }
  > tbody > tr.table-danger > td{
    background-color: $danger;
  }

    .img-wrapper{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;
    }

    .img-row{
      max-width: 60px;
      width: 60px;
    }

    .form-check{
        margin: 0;
        margin-top: 5px;

        & label .form-check-sign::before,
        & label .form-check-sign::after{
            top: -17px;
            left: 4px;
        }
    }

    .btn{
        margin: 0;
    }

    small,.small{
      font-weight: 300;
    }

    .card-tasks .card-body &{
        margin-bottom: 0;

        > thead > tr > th,
        > tbody > tr > th,
        > tfoot > tr > th,
        > thead > tr > td,
        > tbody > tr > td,
        > tfoot > tr > td{
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

   > thead > tr > th{
       border-bottom-width: 1px;
       font-size: 12px;
       text-transform: uppercase;
       font-weight: $font-weight-extra-bold;
       border: 0;
       color: rgba($white, 0.7);
   }

   .radio,
   .checkbox{
       margin-top: 0;
       margin-bottom: 0;
       padding: 0;
       width: 15px;

       .icons{
           position: relative;
       }

        label{
            &:after,
            &:before{
                top: -17px;
                left: -3px;
            }
        }
   }
   > thead > tr > th,
   > tbody > tr > th,
   > tfoot > tr > th,
   > thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td{
       border-color: rgba(255, 255, 255, 0.1);
       padding: 12px 7px;
       vertical-align: middle;
   }

   &.table-shopping tbody tr:last-child td{
      border: none;
   }

   .th-description{
       max-width: 150px;
   }
   .td-price{
       font-size: 26px;
       font-weight: $font-weight-light;
       margin-top: 5px;
       position: relative;
       top: 4px;
       text-align: right;
   }
   .td-total{
        font-weight: $font-weight-bold;
        font-size: $h5-font-size;
        padding-top: 20px;
        text-align: right;
    }

   .td-actions .btn{
    margin: 0px;
    }

    > tbody > tr{
        position: relative;
    }

    > tfoot > tr {
      color: hsla(0,0%,100%,.7);
      text-transform: uppercase;
    }
}

.table-responsive{
  overflow: scroll;
  padding-bottom: 10px;
}

#tables .table-responsive{
    margin-bottom: 30px;
}

// Used with table-responsive
.table-show-text {
    table > tbody > tr > td {
      max-width: none !important;
    }
    @media (min-width: 767.98px) {
      overflow-x: auto;
    }
}

.small-desktop-table {
  @media (max-width: 926.00px) {
    width: 100%;
  }
  @media (min-width: 926.00px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.resource-matrix-count {
  font-size: 1rem; 
}

.devices-table {
  border-collapse: separate;
  border-spacing: 0 10px;

  tbody > tr {
    background-color: white;
    border: 1px solid $table-dark-border-color;
    border-radius: 1px;
    box-shadow: $box-shadow-sm;

    & > td .icon {
      font-size: $font-size-xl;
    }
  }
}

.tria-table {
  width: 100%;
  border-collapse:separate;
  border-spacing:0 12px;
}
table.tria-table tbody {
  
}
table.tria-table thead tr th {
  padding-bottom: 6px;
}
.tria-table > thead > tr > th {
  color: #BFC5D2 !important;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  //font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  //font-family: ".SF NS Display";
  font-size: 12px;
  letter-spacing: 1.12px;
  line-height: 15px;
}
.white-content .tria-table > thead tr th {
   // Border spacing gives padding already, remove excess
   padding-top: 0px;
   padding-bottom: 0px;
   text-transform: uppercase;
}
.white-content .tria-table tbody tr {
  td {
    color: #2E384D !important;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    border-top: 1px solid rgba(46,91,255,0.08) !important;
    border-bottom: 1px solid rgba(46,91,255,0.08) !important;
    border-radius: 1px;

    &:first-child {
      border-left: 1px solid rgba(46,91,255,0.08) !important;
    }

    &:last-child {
      border-right: 1px solid rgba(46,91,255,0.08) !important;
    }
  }

  &.checked td {
    border-top: 1px solid $blue1 !important;
    border-bottom: 1px solid $blue1 !important;

    &:first-child {
      border-left: 1px solid $blue1 !important;
    }

    &:last-child {
      border-right: 1px solid $blue1 !important;
    }
  }

  &.hovered:hover td:first-child {
    border-left: 2px solid $blue1 !important;
  }
}

/*.white-content .tria-table > tbody > tr:hover > td:first-child {
  border-left: 1px solid blue !important;
  border-radius: 3%;
}*/

.white-content .table-indent > tbody > tr > td:first-child {
  padding-left: 20px !important;
}
.white-content .table-indent > tbody > tr > td:last-child {
  padding-right: 20px !important;
}

table.field-value-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 20px;

  & > tbody> tr > td{
      vertical-align: top;
  }
  & > tbody> tr > td:first-child{
      font-family: 'Roboto', sans-serif;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0;
      color: $gray6;
  }
  & > tbody> tr > td:nth-child(2){
      text-align: right;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      line-height: 13px;
      color: $gray4;
  }
}
.tria-table.white {
  & tbody tr {
    background: white;
  }
}

.tria-table.compact-header, .white-content .table.tria-table {
  & > thead > tr > th {
    padding-bottom: 0px;
  }

  & tbody tr:first-child {

  }
}

table.tria-table tbody tr td {
  color: $gray6 !important;
}

.tria-table-spaced {
  width: 100%;
  color:#2E384D;
  font-size: 0.9375rem;

  border-collapse: separate;
  border-spacing: 0 7px;

  & thead {
    border-collapse: collapse;
  }

  & thead th {
    border-collapse: collapse;
  }
  
  & thead th {
    color: #BFC5D2;
    font-family: Roboto, sans-serif;
    font-size: 12px;
    letter-spacing: 1.12px;
    font-weight: 400;
    text-transform: uppercase;

    &:first-child {
      padding-left: 25px;
    }
  }

  & td {
    padding: .625rem;
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
    border-top: 1px solid rgba(46,91,255,0.08);
    border-bottom: 1px solid rgba(46,91,255,0.08);

    font-weight: 400;

    &:first-child {
      padding-left: 25px;
    }
  }

  & tbody tr {
    & td:first-child {
      border-left: 1px solid rgba(46,91,255,0.08);
    }
    & td:last-child {
      border-right: 1px solid rgba(46, 91, 255, 0.08);
    }
    
    &.checked {
      & td {
        border-top: 1px solid $blue1;
        border-bottom: 1px solid $blue1;
      }
      & td:first-child {
        border-left: 1px solid $blue1;
      }
      & td:last-child {
        border-right: 1px solid $blue1;
      }
    }
  }
}

.tria-table-spaced.hover tbody tr:hover td:first-child {
  border-left: 2px solid $blue1;
  padding-left: 24px; // Change padding by 1px to offset slightly wider border
}

// Very specific class for the invoices table
.tria-table-spaced.height-fixed tbody tr td {
  height: 65px;
}

.tria-table.runbooks-table {
  table-layout: fixed;

  tbody > tr {
    td:first-child { padding-left: 10px !important; }
    td:last-child { padding-right: 10px !important; }
  }  
}
